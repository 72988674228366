import React from 'react';
// import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import avatar from '../images/rafayepes.jpg';
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

function Image() {
  return (
    <img
      style={{
        borderRadius: '50% 50%',
        margin: '0 auto',
        width: '200px',
        height: '200px',
        display: 'block',
      }}
      src={avatar}
      alt="Rafa Yepes (@rafayepes) profile picture"
    />
  );
}

// const Image = () => (
//   <StaticQuery
//     query={graphql`
//       query {
//         placeholderImage: file(relativePath: { eq: "rafayepes-avatar.png" }) {
//           childImageSharp {
//             fixed(width: 180, height: 180) {
//               ...GatsbyImageSharpFixed
//             }
//           }
//         }
//       }
//     `}
//     render={data => (
//       <Img
//         fixed={data.placeholderImage.childImageSharp.fixed}
//         style={{
//           borderRadius: '50% 50%',
//           margin: '0 auto',
//           width: '180px',
//           height: '180px',
//           display: 'block',
//         }}
//       />
//     )}
//   />
// );
export default Image;
