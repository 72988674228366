import React from 'react'
import { Link } from 'gatsby'

const Header = ({ siteTitle }) => (
  <div
    style={{
      position: 'relative',
      width: '100%',
      height: '100%',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  />
)

export default Header
