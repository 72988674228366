import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/SEO';
import SocialNetworkLinks from './SocialNetworkLinks';
import personalSites from '../personal-sites.json';
import imageSEO from '../images/rafayepes.jpg'


let Link = styled.a({
  color: '#000',
  borderBottom: '1px dashed #000',
  transition: 'all 0.3s ease-in-out 0s',
  ':hover': {
    color: '#fff',
    backgroundColor: '#000',
  },
});

const IndexPage = () => (
  <Layout>
    <SEO image={imageSEO} />
    <div
      style={{
        margin: '0 auto',
        marginBottom: '1.45rem',
        paddingBottom: '80px',
      }}
    >
      <Image />
      <h1
        style={{
          flex: '0 0 auto',
          fontFamily: 'Montserrat',
          padding: 0,
          textAlign: 'center',
          fontWeight: '500',
          fontSize: '30px',
          lineHeight: '35px',
          margin: '10px',
        }}
      >
        Rafa Yepes{' '}
        <a
          href={personalSites.twitter}
          style={{
            display: 'block',
            fontWeight: '200',
            fontSize: '16px',
            lineHeight: '30px',
            color: '#6a6a6a',
          }}
        >
          (@rafayepes)
        </a>
      </h1>
      <h2
        style={{
          flex: '0 0 auto',
          fontFamily: 'Montserrat',
          padding: 0,
          textAlign: 'center',
          fontWeight: '200',
          fontSize: '24px',
          lineHeight: '35px',
          margin: '10px',
        }}
      >
        Front End Engineer
        <br />
        at{' '}
        <Link href="https://facebook.com/">
          Facebook
        </Link>
      </h2>
      <div
        style={{
          flex: '0 0 auto',
          padding: 0,
          margin: '16px 0',
          position: 'fixed',
          bottom: '0px',
          width: '100%',
        }}
      >
        <SocialNetworkLinks />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
