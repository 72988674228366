import React from 'react';
import styled from '@emotion/styled';
import personalSites from '../personal-sites.json';
import TwitterIcon from '../icons/twitter-circle-black-optimized.svg';
import GitHubIcon from '../icons/github-circle-optimized.svg';
import LinkedInIcon from '../icons/linkedin-circle.svg';

let IconLink = styled.a({
  height: '64px',
  width: '64px',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 6px 6px',
  position: 'relative',
  display: 'block',
  borderRadius: '64px',
  margin: '8px',
  transition: 'transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1)',
  ':hover': {
    transform: 'translate3d(0, 3px, 0)',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 3px 3px',
  },
});

let Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0, 0',
  position: 'relative',
});

export default function SocialNetworkLinks() {
  return (
    <Wrapper>
      <IconLink href={personalSites.twitter} aria-label="Rafa's Twitter profile" >
        <TwitterIcon />
      </IconLink>
      <IconLink href={personalSites.github} aria-label="Rafa's GitHub profile" >
        <GitHubIcon />
      </IconLink>
      <IconLink href={personalSites.linkedin} aria-label="Rafa's LinkedIn profile" >
        <LinkedInIcon />
      </IconLink>
    </Wrapper>
  );
}
